// Generated by Framer (38f2e7f)

import { addFonts, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {JQViNPK2f: {hover: true}};

const serializationHash = "framer-9d3kC"

const variantClassNames = {JQViNPK2f: "framer-v-1pvhd5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "JQViNPK2f", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"linkedin.com"}><motion.a {...restProps} {...gestureHandlers} aria-label={"LinkedIn"} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1pvhd5", className, classNames)} framer-1w6xv2w`} data-framer-name={"in"} layoutDependency={layoutDependency} layoutId={"JQViNPK2f"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"JQViNPK2f-hover": {opacity: 0.5}}} {...addPropertyOverrides({"JQViNPK2f-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-1omitx8"} data-framer-name={"in"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"V8d97vDUP"} svg={"<svg width=\"24\" height=\"24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\"><path d=\"M22.223 0H1.772C.792 0 0 .773 0 1.73v20.536C0 23.222.792 24 1.772 24h20.451c.98 0 1.777-.778 1.777-1.73V1.73C24 .773 23.203 0 22.223 0ZM7.12 20.452H3.558V8.995H7.12v11.457ZM5.34 7.434a2.064 2.064 0 1 1 0-4.125 2.063 2.063 0 0 1 0 4.125Zm15.112 13.018h-3.558v-5.57c0-1.326-.024-3.037-1.852-3.037-1.851 0-2.133 1.449-2.133 2.944v5.663H9.356V8.995h3.413v1.566h.047c.473-.9 1.636-1.852 3.365-1.852 3.605 0 4.27 2.372 4.27 5.457v6.286Z\" fill=\"#fff\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9d3kC.framer-1w6xv2w, .framer-9d3kC .framer-1w6xv2w { display: block; }", ".framer-9d3kC.framer-1pvhd5 { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-9d3kC .framer-1omitx8 { flex: none; height: 24px; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ocmVuCFln":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruxIj_166U: React.ComponentType<Props> = withCSS(Component, css, "framer-9d3kC") as typeof Component;
export default FrameruxIj_166U;

FrameruxIj_166U.displayName = "ln";

FrameruxIj_166U.defaultProps = {height: 24, width: 24};

addFonts(FrameruxIj_166U, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})